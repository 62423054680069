@use "navs" as nav;
@use "colors-lib" as *;

.side-nav-bar {
  position: fixed;
  z-index: 1001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .side-nav-bar-overlay {
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
  }

  .close-container {
    margin-left: 15px;
    width: 48px;
    height: 48px;
    .close-btn {
      color: white;
      height: 48px;
      cursor: pointer;
      background-image: nav.nav-cancel($white);
      background-size: 16px 16px;
      background-position: 16px 18px;
      background-repeat: no-repeat;
    }
  }

  .side-nav-bar-menu-container {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $grey-20;
    display: flex;
    flex-direction: column;

    .side-nav-bar-close {
      display: inline-block;
      font-size: 2.5em;
      margin: 24px 0px 0px 24px;
      cursor: pointer;
    }
  }
}

.side-nav-bar-overlay-collapsed {
  background: transparent !important;
}

.side-nav-bar-collapsed {
  visibility: collapse !important;
}
