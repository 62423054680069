$green-01: #F0F7E7;
$green-02: #e0f8c5;
$green-10: #c1f28c;
$green-12: #a7dd74;
$green-15: #80be50;
$green-17: #6ca63e;
$green-20: #65983d;
$green-35: #55822e;
$green-50: #457020;
$green-70: #283F12;

$mgreen-02: #DEE2C7;
$mgreen-05: #CAD19D;
$mgreen-10: #8F9358;
$mgreen-20: #66703D;
$mgreen-50: #404428;

$grey-02: #F3F8FB;
$grey-05: #E7F1F7;
$grey-10: #dee7ee;
$grey-11: #d9e1e6;
$grey-12: #d0d9e0;
$grey-15: #a9bac7;
$grey-16: #91a2b0;
$grey-17: #778b9b;
$grey-18: #607A8D;
$grey-20: #435a6d;
$grey-30: #113a4c;
$grey-35: #082533;

$blue-02: #f2faff;
$blue-05: #dff3ff;
$blue-07: #C5E8FF;
$blue-10: #acdeff;
$blue-12: #94c9eb;
$blue-15: #66b2e2;
$blue-20: #3d91c6;
$blue-25: #0D73D4;
$blue-35: #2d83b9;
$blue-50: #1e75ac;
$blue-70: #115389;

$yellow-10: #fff0CA;
$yellow-15: #FFE24B;
$yellow-20: #f9c400;
$yellow-30: #FC9E00;
$yellow-35: #ff7800;

$red-05: #FC7E87;
$red-15: #fc5b3f;
$red-20: #b33925;
$red-30: #87241A;

$magenta-10: #EEA2F2;
$magenta-20: #ba3eba;
$magenta-30: #9A129E;

$brown-10: #ddccbf;
$brown-15: #bc9779;
$brown-20: #a67c52;
$brown-50: #7c4c37;

$black: #000;
$white: #fff;

$box-shadow: 0 0 20px 5px rgba(17, 58, 76, 0.2);

//legacy
$green-04: #d1ed9a;
$green-05: #b7d381;
$green-08: #d0e2ad;
$green-14: #a2c15d;
