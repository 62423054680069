@use "_icons" as icons;
@use "_colors-lib" as *;

.finn-text-wrapper {
    font-family: FinnfontLight, Arial,Helvetica,sans-serif;
}

h3 {
    font-family: FinnfontLight, Arial,Helvetica,sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 24px;
}